import setCssVar from 'quasar/src/utils/set-css-var.js';;

export default function liveloTheme() {
    setCssVar("primary", "#0F173F");
    setCssVar("secundary", "#B71C68");
    setCssVar("secondary", "#B71C68");

    setCssVar("grad-1", "#fc9c49");
    setCssVar("grad-2", "#ff0488");

    setCssVar("secundary-rgb", "254, 82, 104");

    // setCssVar("accent", "#FE5268");

    // setCssVar("dark", "#FE5268");

    // setCssVar("positive", "#FE5268");
    // setCssVar("negative", "#FE5268");
    // setCssVar("info", "#FE5268");
}