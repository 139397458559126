<template>
  <div class="item">
    <div class="flex full-width">
      <q-item
        clickable
        dense
        exact
        v-ripple
        :class="[
          'flex items-center',
          showAdm ? 'bg-white text-black' : 'text-white',
        ]"
        active-class="bg-white text-black"
        @click="toogleAdm()"
      >
        <div class="avatar-menu">
          <div class="flex items-center">
            <component
              :is="iconName"
              :color="showAdm ? 'var(--q-primary)' : 'white'"
            ></component>
          </div>
        </div>
        <q-item-section>{{ name }}</q-item-section>
      </q-item>
      <div class="q-mt-sm" v-if="showAdm">
        <div v-for="adminItem in adminMenu" :key="adminItem.route">
          <q-item
            v-if="adminItem.permission"
            clickable
            dense
            :to="{ name: adminItem.routeName }"
            exact
            v-ripple
            :class="[
              'q-ml-lg  flex items-center',
              route.name != adminItem.routeName
                ? 'text-white'
                : 'bg-white text-black',
            ]"
            class="text-white flex items-center"
            active-class=""
          >
            <div class="avatar-menu">
              <component
                :is="adminItem.icon"
                :color="
                  route.name == adminItem.routeName
                    ? 'var(--q-primary)'
                    : 'white'
                "
              ></component>
            </div>
            <q-item-section>{{ adminItem.name }}</q-item-section>
          </q-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//----VUEJS
import { ref } from "vue";

import { useRoute } from "vue-router";

export default {
  name: "DropListMenu",
  props: {
    iconName: Object,
    name: String,
    adminMenu: Array,
  },

  setup(props) {
    //----CONSTANTS
    const route = useRoute();

    //----VARIABLES
    let showAdm = ref(false);

    function getIconLigthMenu(iconName) {
      return `@/assets/icons/menu/white_icon/${iconName}.svg`;
    }

    function getIconDarkMenu(iconName) {
      return `@/assets/icons/menu/dark_icon/${iconName}.svg`;
    }

    function toogleAdm() {
      showAdm.value = !showAdm.value;
    }

    return {
      //----CONSTANTS
      route,

      //----VARIABLES
      showAdm,

      //----FUNCTIONS
      getIconLigthMenu,
      getIconDarkMenu,
      toogleAdm,
    };
  },
};
</script>

<style lang="scss" scoped>
.menu-list,
.dropdown-list {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff23;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #1a1a1a;
  }

  .item {
    display: flex;
    align-items: center;
    margin: 4px 0;

    .q-item {
      width: 100%;
      border-radius: 20px;
    }

    .avatar-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: auto;

      margin-right: 16px;
    }
  }
}
</style>
