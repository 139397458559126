import Notify from 'quasar/src/plugins/Notify.js';;

export default class CanUserAccessClass {
    canUserAccess(permission, notify = true){
        let array = JSON.parse(localStorage.getItem("vuex")).permissions;
        let result = array.filter(element => {
            return element.routeName === permission;
        });
        if(result.length === 0){
            if(notify){
                Notify.setDefaults({
                    position: 'top',
                    timeout: 5000,
                    textColor: 'white',
                    color: 'red-9'
                })

                Notify.create('Você não tem permissão para executar está ação.')
            }
            return false;
        }
        return true;
    }
}