export default class Colors {
  getColorBgBadge(name) {
    switch (name) {
      case "add":
        return "item-bg-pink";
      case "Conquistas":
        return "item-bg-yellow";
      case "Conhecimento":
        return "item-bg-purple";
      case "Dúvidas":
        return "item-bg-blue";
      case "Quiz":
        return "item-bg-green";
      case "file":
        return "item-bg-blue";
      default:
        return "item-bg-depp-green";
    }
  }

  getLevelColors(name) {
    switch (name.toLowerCase()) {
      case "ferro":
        return "#0B27FD";
      case "bronze":
        return "#E27F55";
      case "prata":
        return "#8B8B8B";
      case "ouro":
        return "#F0AA26";
      case "platina":
        return "#3794A5";
      case "diamante":
        return "#9463C6";
      case "imortal":
        return "#9E1524";
      default:
        return "#8B8B8B";
    }
  }
}
