import Layout from "../../views/Layout.vue";

export default {
  path: "/games",
  name: "games",
  component: Layout,
  redirect: { path: "/home" },
  children: [
    {
      path: "/launcher",
      name: "launcher",
      component: () => import("@/pages/games/Launcher.vue"),
    },
  ],
};
