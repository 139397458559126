<template>
  <svg
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M19.7615 40.5511L19.7619 40.5515C20.9139 41.7306 22.3838 42.5402 23.9901 42.8737C24.3247 42.9432 24.662 42.9913 25 43.0183V50.8016C24.7661 50.7794 24.5328 50.7443 24.301 50.6962C23.0401 50.4344 21.8773 49.7973 20.9596 48.8587C20.9596 48.8587 20.9595 48.8587 20.9595 48.8586C20.9593 48.8584 20.9591 48.8582 20.959 48.8581L19.7227 47.5916L19.7226 47.5916L18.6493 48.6395C19.7226 47.5916 19.7226 47.5916 19.7225 47.5915L19.7222 47.5911L19.7207 47.5897L19.7151 47.5838L19.6925 47.5608L19.6039 47.47L19.262 47.1197L17.9939 45.8203C16.9174 44.717 15.4165 43.1784 13.7193 41.4374C10.325 37.9556 6.14638 33.6653 3.006 30.4285C2.04515 29.4353 1.5 28.0863 1.5 26.6732C1.5 25.3726 1.96179 24.1262 2.78441 23.1617L18.7221 39.4865L19.7615 40.5511Z"
      :fill="getColorBgBadge(level)"
      :stroke="getColorBgBadge(level)"
      stroke-width="3"
    />
    <path
      opacity="0.5"
      d="M26 41.6861C26.7533 41.6461 27.4988 41.478 28.2053 41.1847C28.4108 41.0984 28.6123 41.0026 28.8099 40.8974C29.4173 40.5707 29.9729 40.1517 30.4579 39.6546L31.5012 38.5859L48.4749 21.2L49.3365 22.1108C50.5522 23.3985 51.2246 25.1243 51.2083 26.9156C51.192 28.7069 50.4884 30.2356 49.2495 31.5L32.7421 48.6027L31.5012 49.6868C30.9257 50.2776 30.0764 50.7758 29.3553 51.1643C29.1208 51.2911 28.8824 51.4356 28.64 51.5354C27.7944 51.8862 26.9017 52.0861 26 52.1314"
      :stroke="getColorBgBadge(level)"
      stroke-width="0.3"
    />
    <path
      d="M40.6427 12.8899L29.842 1.82675L29.2294 1.19932C29.0378 1.00268 28.8273 0.826353 28.6011 0.673064C28.2744 0.451567 27.9175 0.281037 27.5419 0.167068C27.4181 0.129287 27.2929 0.0983341 27.1665 0.0740462C26.4061 -0.075849 25.6196 0.00287987 24.902 0.300732C24.7808 0.349308 24.6622 0.405948 24.5463 0.470715C24.1896 0.662217 23.8631 0.907842 23.578 1.19932V1.19932L22.9655 1.82675L12.1687 12.8858C11.4204 13.6532 11 14.6935 11 15.7781C11 16.8627 11.4204 17.9031 12.1687 18.6704L22.9655 29.7335L23.578 30.361C24.1336 30.9293 24.8402 31.3175 25.6098 31.4773C26.3793 31.6372 27.1777 31.5614 27.9055 31.2596C28.0267 31.2083 28.1453 31.1516 28.2612 31.0896C28.618 30.8981 28.9444 30.6525 29.2294 30.361V30.361L29.842 29.7335L40.6387 18.6744C41.3876 17.9075 41.8087 16.8677 41.8094 15.783C41.8102 14.6984 41.3905 13.6578 40.6427 12.8899Z"
       :fill="getColorBgBadge(level)"
    />
  </svg>

</template>

<script>
//----UTILS
import ColorsClass from "@/utils/styles/colors/getColors.js";


export default {
  name: "LevelTwo",
  props: {
    level: String,
  },
  setup() {
    //-----CONSTANTE
    const Colors = new ColorsClass();


    function getColorBgBadge(name = "Bronze") {
      return Colors.getLevelColors(name);
    }

    return {
      //----FUNCTIONS
      getColorBgBadge,
    };
  },
};
</script>

<style lang="scss" scoped></style>
