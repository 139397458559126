import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import VueGridLayout from "vue-grid-layout";
import VueApexCharts from "vue3-apexcharts";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
import '@/styles/_global.scss'

const app = createApp(App)
app.use(Quasar, quasarUserOptions)
app.use(VueGridLayout);
app.use(VueApexCharts);
app.component('QuillEditor', QuillEditor);

app.use(Vue3VideoPlayer, {
    lang: 'pt-BR'
})
app.use(store)
app.use(router)
app.mount('#app')