<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5312 3.0625L8.9375 0.4375C8.65625 0.15625 8.25 0 7.875 0H1.5C0.65625 0 0 0.6875 0 1.5V14.5C0 15.3438 0.65625 16 1.5 16H10.5C11.3125 16 12 15.3438 12 14.5V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM10.375 4H8V1.625L10.375 4ZM1.5 14.5V1.5H6.5V4.75C6.5 5.1875 6.8125 5.5 7.25 5.5H10.5V14.5H1.5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Reports",
  props: {
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
    color: String,
  },
  setup() {},
};
</script>

<style lang="scss" scoped></style>
