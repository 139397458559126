<template>
  <svg
    width="52"
    height="54"
    viewBox="0 0 52 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.8588 12.8899L29.058 1.82675L28.4455 1.19932C28.2538 1.00268 28.0433 0.826353 27.8171 0.673064C27.4905 0.451567 27.1335 0.281037 26.758 0.167068C26.6341 0.129287 26.509 0.0983341 26.3825 0.0740462C25.6222 -0.075849 24.8357 0.00287987 24.118 0.300732C23.9969 0.349308 23.8783 0.405948 23.7624 0.470715C23.4056 0.662217 23.0792 0.907842 22.7941 1.19932L22.1816 1.82675L11.3848 12.8858C10.6364 13.6532 10.2161 14.6935 10.2161 15.7781C10.2161 16.8627 10.6364 17.9031 11.3848 18.6704L22.1816 29.7335L22.7941 30.361C23.3497 30.9293 24.0563 31.3175 24.8258 31.4774C25.5953 31.6372 26.3937 31.5614 27.1215 31.2596C27.2427 31.2083 27.3613 31.1516 27.4773 31.0896C27.834 30.8981 28.1605 30.6525 28.4455 30.361L29.058 29.7335L39.8548 18.6744C40.6037 17.9075 41.0248 16.8677 41.0255 15.783C41.0262 14.6984 40.6066 13.6578 39.8588 12.8899Z"
      :fill="getColorBgBadge(level)"
    />
    <path
      opacity="0.5"
      d="M49.3364 22.6817L48.4749 21.7708L31.5011 39.1568L30.4578 40.2255C29.9729 40.7226 29.4172 41.1416 28.8098 41.4683C28.6122 41.5735 28.4107 41.6693 28.2052 41.7556C26.9657 42.2701 25.6058 42.3994 24.295 42.1273C22.9843 41.8552 21.7808 41.1938 20.8348 40.2255L19.7954 39.1609L2.77029 21.7223L1.9285 22.5965C0.693107 23.8732 0 25.598 0 27.3955C0 29.193 0.693107 30.9177 1.9285 32.1943C8.21214 38.6711 18.6493 49.3617 18.6493 49.3617L19.8863 50.6288C21.01 51.7785 22.4394 52.564 23.996 52.8872C25.5527 53.2104 27.1678 53.0571 28.6399 52.4464C28.8823 52.3466 29.1207 52.2331 29.3552 52.1063C30.0764 51.7178 30.7359 51.2197 31.3115 50.6288L32.5524 49.3578L49.2494 32.2551C50.4884 30.9907 51.192 29.2778 51.2082 27.4865C51.2245 25.6952 50.5521 23.9694 49.3364 22.6817Z"
      :fill="getColorBgBadge(level)"
    />
  </svg>
</template>

<script>
//----UTILS
import ColorsClass from "@/utils/styles/colors/getColors.js";

export default {
  name: "LevelThree",
  props:{
    level:String
  },
  setup(){
    //-----CONSTANTE
    const Colors = new ColorsClass();


    function getColorBgBadge(name = 'Silver') {
      return  Colors.getLevelColors(name);
    }  
    return {
      //----FUNCTIONS
      getColorBgBadge,
    };
  }
};
</script>

<style lang="scss" scoped></style>
