<template>
<svg :width="width" :height="height" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.8125 6.75L16 5.25V2.25C16 2.125 15.875 2 15.75 2H14.75C14.5938 2.03125 14.5 2.125 14.5 2.25V4.03125L10.0938 0.40625C9.84375 0.1875 9.34375 0.03125 9 0.03125C8.625 0.03125 8.125 0.1875 7.875 0.40625L0.15625 6.75C0.0625 6.84375 0 7 0 7.125C0 7.21875 0.03125 7.375 0.09375 7.4375L0.4375 7.84375C0.5 7.9375 0.6875 8 0.8125 8C0.90625 8 1.0625 7.96875 1.125 7.90625L2 7.1875V13C2 13.5625 2.4375 14 3 14H7C7.53125 14 7.96875 13.5625 8 13V9.75H10V13C10 13.5625 10.4375 14 11 14H15C15.5312 14 15.9688 13.5625 16 13.0312V7.1875L16.8438 7.90625C16.9062 7.96875 17.0625 8.03125 17.1562 8.03125C17.2812 8.03125 17.4688 7.9375 17.5625 7.84375L17.875 7.4375C17.9375 7.375 18 7.21875 18 7.125C18 7 17.9062 6.84375 17.8125 6.75ZM14.4688 12.5H11.5V9.25C11.4688 8.71875 11.0312 8.28125 10.5 8.25H7.5C6.9375 8.28125 6.5 8.71875 6.5 9.25V12.5H3.5V5.96875L9 1.4375L14.5 5.96875L14.4688 12.5Z" :fill="color"/>
</svg>
</template>
  
  <script>
  export default {
    name: "Home",
    props: {
      width: {
        type: Number,
        default: 20,
      },
      height: {
        type: Number,
        default: 18,
      },
      color: String,
    },
    setup() {},
  };
  </script>
  
  <style lang="scss" scoped></style>
  
