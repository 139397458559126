<template>
    <!-- <?xml version="1.0" ?> -->
  <svg
    id="Capa_1"
    style="enable-background: new 0 0 561.7 440.3"
    version="1.1"
    viewBox="0 0 561.7 440.3"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M509.7,405.7V174.1c0-23.4-19-42.4-42.4-42.4h-36.6c-23.4,0-42.4,19-42.4,42.4v231.5h-38.1V77.1c0-23.4-19-42.4-42.4-42.4  h-36.6c-23.4,0-42.4,19-42.4,42.4v328.6h-38.1V243.5c0-23.4-19-42.4-42.4-42.4h-36.6c-23.4,0-42.4,19-42.4,42.4v162.2H34.7V0H0  v440.3h561.7v-34.7H509.7z M263.5,77.1c0-4.3,3.5-7.7,7.7-7.7h36.6c4.3,0,7.7,3.5,7.7,7.7v328.6h-52V77.1z M104,243.5  c0-4.3,3.5-7.7,7.7-7.7h36.6c4.3,0,7.7,3.5,7.7,7.7v162.2h-52V243.5z M423,405.7V174.1c0-4.3,3.5-7.7,7.7-7.7h36.6  c4.3,0,7.7,3.5,7.7,7.7v231.5H423z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Dashboard",
  props: {
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 14,
    },
    color: String,
  },
  setup() {},
};
</script>

<style lang="scss" scoped></style>
