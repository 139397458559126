
<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.2109 0.125C7.69141 0.125 4.83594 1.32031 4.83594 2.78125V4.54102C2.3457 4.90625 0.585938 5.90234 0.585938 7.03125V14.4688C0.585938 15.9629 3.44141 17.125 6.96094 17.125C10.4805 17.125 13.3359 15.9629 13.3359 14.4688V12.7422C15.793 12.377 17.5859 11.3809 17.5859 10.2188V2.78125C17.5859 1.32031 14.7305 0.125 11.2109 0.125ZM11.7422 14.3691C11.4102 14.7344 9.7168 15.5312 6.96094 15.5312C4.17188 15.5312 2.51172 14.7344 2.17969 14.3691V13.041C3.3418 13.6055 5.03516 13.9375 6.96094 13.9375C8.85352 13.9375 10.5469 13.6055 11.7422 13.041V14.3691ZM11.7422 11.1816C11.4102 11.5469 9.7168 12.3438 6.96094 12.3438C4.17188 12.3438 2.51172 11.5469 2.17969 11.1816V9.6875C3.3418 10.3516 5.03516 10.75 6.96094 10.75C8.85352 10.75 10.5469 10.3516 11.7422 9.6875V11.1816ZM6.96094 9.15625C4.30469 9.15625 2.17969 8.45898 2.17969 7.5625C2.17969 6.69922 4.30469 5.96875 6.96094 5.96875C9.58398 5.96875 11.7422 6.69922 11.7422 7.5625C11.7422 8.45898 9.58398 9.15625 6.96094 9.15625ZM15.9922 10.1191C15.7266 10.3848 14.8301 10.8496 13.3359 11.1152V9.52148C14.3652 9.38867 15.2617 9.12305 15.9922 8.79102V10.1191ZM15.9922 6.93164C15.7266 7.19727 14.8301 7.66211 13.3359 7.92773V7.03125C13.3359 6.79883 13.2363 6.56641 13.1035 6.36719C14.2324 6.16797 15.2285 5.86914 15.9922 5.4375V6.93164ZM11.2109 4.90625C11.0449 4.90625 10.8789 4.90625 10.7129 4.90625C9.84961 4.64062 8.82031 4.47461 7.69141 4.4082C6.92773 4.10938 6.42969 3.74414 6.42969 3.3125C6.42969 2.44922 8.55469 1.71875 11.2109 1.71875C13.834 1.71875 15.9922 2.44922 15.9922 3.3125C15.9922 4.20898 13.834 4.90625 11.2109 4.90625Z" :fill="color"/>
  </svg>
</template>

<script>

export default {
  name: "Coins",
  props:{
    width:{
      type:Number,
      default:18
    },
    height:{
      type:Number,
      default:18
    },
    color:String
  },
  setup(){
  }
};
</script>

<style lang="scss" scoped></style>
