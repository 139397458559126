<template>
<svg :width="width" :height="height" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.1094 2H13.1094L11.3907 0.3125C11.2032 0.125 10.9532 0 10.6719 0H6.10941C5.26566 0 4.60941 0.6875 4.60941 1.5V4H2.10941C1.26566 4 0.609411 4.6875 0.609411 5.5V14.5C0.609411 15.3438 1.26566 16 2.10941 16H15.1094C15.9219 16 16.6094 15.3438 16.6094 14.5V12H19.1094C19.9219 12 20.6094 11.3438 20.6094 10.5V3.5C20.6094 2.6875 19.9219 2 19.1094 2ZM15.1094 14.5H2.10941V5.5H4.60941V10.5C4.60941 11.3438 5.26566 12 6.10941 12H15.1094V14.5ZM19.1094 10.5H6.10941V1.5H10.4844L12.1719 3.21875C12.3594 3.40625 12.6094 3.5 12.8907 3.5H19.1094V10.5Z" :fill="color"/>
</svg>
</template>

<script>
export default {
  name: "History",
  props: {
    width: {
      type: Number,
      default: 21,
    },
    height: {
      type: Number,
      default: 16,
    },
    color: String,
  },
  setup() {},
};
</script>

<style lang="scss" scoped></style>
