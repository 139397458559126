<template>
  <div class="main">
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// import SideBar from "@/components/sidebar/SideBar.vue";

export default {
  name: "LayoutEmpty",
  setup() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: var(--q-primary);
  overflow: auto;

  .main {
    width: 100vw;
    height: 100vh;
    
  }

  @media (min-width: 1040px) {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .main {
      width: 100vw;
      height: 100vh;
    }
  }
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .content {
    padding: 0% !important;
    background: #fdfdfd;
    width: 100%;
    height: 100%;
    border-radius: 40px;

    @media (min-width: 800px) {
      padding: 0.5% 1%; 
    }
  }
}
</style>
