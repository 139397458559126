import defaultTheme from "@/utils/styles/themes/defaultTheme";
import dasaTheme from "@/utils/styles/themes/dasaTheme";
import liveloTheme from "@/utils/styles/themes/livelo";

export default class ThemeApp {
  setThemeById(id) {
    switch (id) {
      case 14:
        dasaTheme();
        break
      case 16:
        liveloTheme();
        break
      default:
        defaultTheme();
        break;
    }
  }
}
