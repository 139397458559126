<template>
  <q-list>
    <div v-for="item in menuOptions" :key="item.routeName">
      <div
        class="item"
        v-if="
          item.routeName != 'users' &&
          item.routeName != 'groups' &&
          item.routeName != 'dashboard'
        "
      >
        <q-item
          clickable
          dense
          :to="{ name: item.routeName }"
          exact
          v-ripple
          :class="[
            route.name != item.routeName
              ? 'text-white flex items-center'
              : 'bg-white text-black',
          ]"
        >
          <div class="avatar-menu">
            <div
              class="flex items-center"
              v-for="component in icons"
              :key="component.name"
            >
              <component
                v-if="item.icon == component.name"
                :is="component.path"
                :color="
                  route.name == item.routeName ? 'var(--q-primary)' : 'white'
                "
              ></component>
            </div>
          </div>
          <q-item-section>{{ item.name }}</q-item-section>
          <q-item-section
            side
            v-if="chatData.isNewMessage && item.name == 'Chat'"
          >
            <q-badge color="red" :label="chatData.quantity" />
          </q-item-section>
        </q-item>
      </div>
    </div>
    <slot name="moreOptions"></slot>
  </q-list>
</template>

<script>
//----ICONS
import Chat from "@/assets/icons/menu/Chat.vue";
import Links from "@/assets/icons/menu/Links.vue";
import Home from "@/assets/icons/menu/Home.vue";
import Knowledge from "@/assets/icons/menu/Knowledge.vue";
import Wiki from "@/assets/icons/menu/Wiki.vue";
import Interactivity from "@/assets/icons/menu/Interactivity.vue";

//----VUEJS
import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ListMenu",
  components: {
    // Chat,
    // Company,
    // Dashboard,
    // Data,
    // Links,
    // History,
    // Home,
    // Knowledge,
    // Reports,
    // Users,
    // Wiki
  },
  props: {
    menuOptions: Array,
    chatData: Object,
  },

  setup(props) {
    //----ICONS
    const icons = ref([
      {
        name: "chat",
        path: Chat,
      },
      {
        name: "links",
        path: Links,
      },
      {
        name: "home",
        path: Home,
      },
      {
        name: "knowledge",
        path: Knowledge,
      },
      {
        name: "wiki",
        path: Wiki,
      },
      {
        name: "interactivity",
        path: Interactivity,
      },
    ]);

    //----CONSTANTS
    const route = useRoute();

    function getIconLigthMenu(iconName) {
      return `@/assets/icons/menu/white_icon/${iconName}.svg`;
    }

    function getIconDarkMenu() {
      return Wiki;
    }

    return {
      //----ICONS
      icons,

      //----CONSTANTS
      route,

      //----FUNCTIONS
      getIconLigthMenu,
      getIconDarkMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
.menu-list,
.dropdown-list {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff23;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--q-primary);
  }

  .item {
    display: flex;
    align-items: center;
    margin: 4px 0;

    .q-item {
      width: 100%;
      border-radius: 20px;
    }

    .avatar-menu {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 24px;
      height: auto;

      margin-right: 16px;
    }
  }
}
</style>
