import { createRouter, createWebHistory } from "vue-router";

//----LAYOUTS
import Login from "../views/Login.vue";
import Layout from "../views/Layout.vue";
import MainLayout from "../views/MainLayout.vue";
import LayoutEmpty from "../views/LayoutEmpty.vue";

//----ROUTES
import coursesRoutes from "./courses/coursesRoutes";
import storeRoutes from "./store/storeRoutes";
import adminRoutes from "./admin/adminRoutes";
import gamesRoutes from "./games/gamesRoutes";

//-----UTILS
import CanUserAccessClass from "../utils/CanUserAccessClass";

let canUserAccessClass = new CanUserAccessClass();

let routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  coursesRoutes,
  adminRoutes,
  storeRoutes,
  gamesRoutes,
  {
    path: "/admin",
    name: "layout",
    component: Layout,
    redirect: { path: "/home" },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/pages/feed/Home.vue"),
      },
      {
        path: "/links",
        name: "links",
        component: () => import("@/pages/admin/links/Favorites.vue"),
      },
      {
        path: "/new-favorite",
        name: "new-favorite",
        component: () => import("@/pages/admin/links/NewFavorite.vue"),
      },

      {
        path: "/wiki",
        name: "wiki",
        component: () => import("@/pages/wikis/Wiki.vue"),
      },
      {
        path: "/new-wiki",
        name: "new-wiki",
        component: () => import("@/pages/wikis/NewWiki.vue"),
        beforeEnter: (to, from, next) => {
          if (canUserAccessClass.canUserAccess(to.name)) {
            next();
          } else {
            next({ name: "wiki" });
          }
        },
      },
      {
        path: "/wiki-explorer",
        name: "wiki-explorer",
        component: () => import("@/pages/wikis/WikiExplorer.vue"),
      },

      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/pages/admin/Dashboard.vue"),
      },
      {
        path: "/chat",
        name: "chat",
        component: () => import("@/pages/student/chat/Chat.vue"),
      },
      {
        path: "/lighting-test",
        name: "lighting-test",
        component: () => import("@/pages/feed/lightingtest/LightingTest.vue"),
      },
      {
        path: "/new-survey",
        name: "new-survey",
        component: () => import("@/pages/feed/Survey/CreateSurvey.vue"),
      },
    ],
  },
  {
    path: "/student",
    name: "student",
    component: Layout,
    children: [
      {
        path: "/student",
        name: "student",
        component: () => import("@/pages/student/Profile/ProfileUser.vue"),
      },
      {
        path: "/edit-student",
        name: "edit-student",
        component: () => import("@/pages/student/Profile/EditUser.vue"),
      },
    ],
  },
  {
    path: "/exam",
    name: "exam",
    component: MainLayout,
    redirect: { path: "/exam" },
    children: [
      {
        path: "/exam",
        name: "exam",
        component: () => import("@/pages/student/exams/Exam.vue"),
      },
      {
        path: "/lighting-test-answer",
        name: "lighting-test-answer",
        component: () => import("@/pages/student/exams/LightingTest.vue"),
      },
    ],
  },
  {
    path: "/lighting-test-list",
    name: "lighting-test-list",
    component: Layout,
    redirect: { path: "/lighting-test-list" },
    children: [
      {
        path: "/lighting-test-list",
        name: "lighting-test-list",
        component: () => import("@/pages/student/exams/ListExamPage.vue"),
      },
    ],
  },
  {
    path: "/certificate",
    name: "certificate",
    component: LayoutEmpty,
    redirect: { path: "/certificate" },
    children: [
      {
        path: "/certificate",
        name: "certificate",
        component: () => import("@/pages/student/Certificate.vue"),
      },
    ],
  },
  {
    path: "/game",
    name: "game",
    component: MainLayout,
    redirect: { path: "/game" },
    children: [
      {
        path: "/game",
        name: "game",
        component: () => import("@/pages/student/Game.vue"),
      },
    ],
  },
  {
    path: "/game-intro",
    name: "game-intro",
    component: MainLayout,
    redirect: { path: "/game-intro" },
    children: [
      {
        path: "/game-intro",
        name: "game-intro",
        component: () => import("@/pages/student/GameWithoutCourse.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
