<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5633 12C14.5633 11.5139 14.4749 11.0719 14.3423 10.63C14.276 10.4753 14.1434 10.3869 13.9666 10.3869H13.7899C13.4142 10.3648 13.0607 10.4974 12.7955 10.7184C12.6629 10.8067 12.6187 10.9835 12.6408 11.1382C12.7292 11.3592 12.8176 11.7569 12.7955 12C12.7955 13.4584 11.6243 14.6295 10.1659 14.6295H6.85138C5.39297 14.6295 4.22183 13.4584 4.22183 12C4.22183 10.5416 5.39297 9.37043 6.85138 9.37043H8.88431C9.01689 9.37043 9.12738 9.30414 9.19367 9.19366C9.37045 8.92849 9.7019 8.50865 9.92287 8.24348C10.1438 8.02251 9.96707 7.62476 9.65771 7.62476C9.12738 7.62476 8.28769 7.62476 6.87348 7.62476C4.4428 7.62476 2.47616 9.59141 2.49825 12C2.47616 14.4086 4.4428 16.3752 6.87348 16.3752C10.4974 16.3752 10.3206 16.3752 10.63 16.331C12.8176 16.1321 14.5633 14.2539 14.5633 12ZM21.4576 12C21.4797 9.59141 19.513 7.62476 17.0823 7.62476C13.4584 7.62476 13.6352 7.62476 13.3258 7.66896C11.1382 7.86783 9.39254 9.74608 9.39254 12C9.39254 12.4861 9.48093 12.9281 9.61351 13.37C9.67981 13.5247 9.81239 13.6131 9.98917 13.6131H10.1659C10.5416 13.6352 10.8951 13.5026 11.1603 13.2816C11.2929 13.1932 11.3371 13.0165 11.315 12.8618C11.2266 12.6408 11.1382 12.2431 11.1603 12C11.1603 10.5416 12.3315 9.37043 13.7899 9.37043H17.1044C18.5628 9.37043 19.734 10.5416 19.734 12C19.734 13.4584 18.5628 14.6295 17.1044 14.6295H15.0715C14.9389 14.6295 14.8284 14.6958 14.7621 14.8063C14.5854 15.0715 14.2539 15.4913 14.0329 15.7565C13.812 15.9775 13.9887 16.3752 14.2981 16.3752C14.8284 16.3752 15.6681 16.3752 17.0823 16.3752C19.513 16.3752 21.4797 14.4086 21.4576 12Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Links",
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    color: String,
  },
  setup() {},
};
</script>

<style lang="scss" scoped></style>
