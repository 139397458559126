<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container class="page">
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref, onMounted } from "vue";
import Layout from "@/views/Layout.vue";

import ThemeApp from "@/utils/styles/themes/Theme";
import {useStore} from "vuex";

export default {
  name: "LayoutDefault",
  components: {
    Layout,
  },

  setup() {
    const themeApp = new ThemeApp();
    const store = useStore()


    function setThemeLorni() {
      const companyId = store.state.userdata?.company_id


      if(!companyId){
        themeApp.setThemeById(1);
        console.log(companyId)
        return
      }
      console.log(companyId)
      themeApp.setThemeById(companyId);
    }

    onMounted(() => {
      setThemeLorni()
    });

    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>

<style lang="scss">
.page {
  height: 100vh;
}
</style>
