<template>
  <svg
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M29.3552 51.384C29.1207 51.5109 28.8823 51.6243 28.6399 51.7241C27.1678 52.3348 25.5527 52.4881 23.996 52.1649C22.4394 51.8417 21.01 51.0563 19.8863 49.9066M29.3552 51.384L19.8863 49.9066M29.3552 51.384C30.0764 50.9955 30.7359 50.4974 31.3115 49.9066L29.3552 51.384ZM19.8863 49.9066L19.9936 49.8018L19.9935 49.8017M19.8863 49.9066L19.9935 49.8017M19.9935 49.8017C21.0967 50.9303 22.4994 51.7009 24.0265 52.018C25.5536 52.3351 27.138 52.1848 28.5824 51.5856L28.5828 51.5854C28.8202 51.4876 29.0539 51.3765 29.2839 51.2521L29.2841 51.252C29.9917 50.8707 30.6391 50.3819 31.204 49.8019L31.2041 49.8018L32.445 48.5307L49.1421 31.428L49.1423 31.4278C50.3537 30.1915 51.0423 28.5159 51.0583 26.7629C51.0742 25.0099 50.4162 23.3216 49.2274 22.0624M19.9935 49.8017L18.7567 48.5347L18.6493 48.6395C18.7566 48.5347 18.7566 48.5346 18.7565 48.5346L18.7562 48.5342L18.7547 48.5327L18.7491 48.5269L18.7265 48.5038L18.6379 48.413L18.2959 48.0627L17.0277 46.7631C15.951 45.6596 14.45 44.1209 12.7527 42.3798C9.35794 38.8976 5.17784 34.6058 2.03616 31.3676C0.828277 30.1193 0.15 28.4322 0.15 26.6732C0.15 24.9141 0.828329 23.2268 2.03629 21.9786L2.03655 21.9783L2.77104 21.2155L19.6881 38.5434L20.7274 39.608L20.7275 39.6081C21.6941 40.5974 22.9242 41.2737 24.2645 41.5519C25.6049 41.8302 26.9955 41.6979 28.2627 41.1719L28.2633 41.1716C28.473 41.0835 28.6787 40.9858 28.8804 40.8784L28.8809 40.8781C29.5018 40.5441 30.0697 40.1159 30.5652 39.608L31.6085 38.5393L48.4731 21.265L49.2273 22.0623M49.2274 22.0624L49.3364 21.9594L49.2273 22.0623M49.2274 22.0624L49.2273 22.0623M49.2274 22.0624L49.2273 22.0623"
      fill="url(#paint0_linear_10812_34847)"
      :stroke="getColorBgBadge(level)"
      stroke-width="0.3"
    />
    <path
      d="M39.859 12.8899L29.0583 1.82675L28.4457 1.19932C28.2541 1.00268 28.0436 0.826353 27.8174 0.673064C27.4907 0.451567 27.1338 0.281037 26.7582 0.167068C26.6344 0.129287 26.5092 0.0983341 26.3828 0.0740462C25.6224 -0.075849 24.8359 0.00287987 24.1183 0.300732C23.9971 0.349308 23.8786 0.405948 23.7626 0.470715C23.4059 0.662217 23.0794 0.907842 22.7944 1.19932L22.1818 1.82675L11.385 12.8858C10.6367 13.6532 10.2163 14.6935 10.2163 15.7781C10.2163 16.8627 10.6367 17.9031 11.385 18.6704L22.1818 29.7335L22.7944 30.361C23.3499 30.9293 24.0565 31.3175 24.8261 31.4774C25.5956 31.6372 26.394 31.5614 27.1218 31.2596C27.243 31.2083 27.3616 31.1516 27.4775 31.0896C27.8343 30.8981 28.1607 30.6525 28.4457 30.361L29.0583 29.7335L39.855 18.6744C40.6039 17.9075 41.025 16.8677 41.0257 15.783C41.0265 14.6984 40.6069 13.6578 39.859 12.8899Z"
      :fill="getColorBgBadge(level)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10812_34847"
        x1="26.5"
        y1="47"
        x2="27.5"
        y2="47"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
//----UTILS
import ColorsClass from "@/utils/styles/colors/getColors.js";

export default {
  name: "LevelOne",
  props:{
    level:String
  },
  setup(){
    //-----CONSTANTE
    const Colors = new ColorsClass();


    function getColorBgBadge(name = 'Silver') {
      return  Colors.getLevelColors(name);
    }  
    return {
      //----FUNCTIONS
      getColorBgBadge,
    };
  }
};
</script>

<style lang="scss" scoped></style>
