import http from "../http-common";
// import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";

export default class ChatServices {
  async createChat(data) {
    return http.post("v1/chat", data);
  }

  async sendMessage(conversationId, message, FunctionText) {
    return http.post(`v1/chat/${conversationId}/send-message`, message, {
      onUploadProgress: (event) => {
        let progress = Math.round((event.loaded * 100) / event.total);
        FunctionText(progress);
      },
    });
  }

  async getAllChats() {
    return http.get("v1/chat");
  }

  async getChat(conversationId, pageNumber, pageSize) {
    return http.get(
      `v1/chat/${conversationId}?PageNumber=${pageNumber}&PageSize=${pageSize}`
    );
  }

  async getChatGroup(conversationId) {
    return http.get(`v1/chat/${conversationId}/group`);
  }

  async deleteGroup(conversationId) {
    return http.delete(`v1/group/disabled-group-chat?chatConversationsId=${conversationId}`);
  }

  async createChatSendMessage(data) {
    return http.post("v1/chat/create-chat-send-message", data);
  }

  async updateChat(data) {
    return http.put("v1/chat", data);
  }

  async removeMessage(conversationId, messageId) {
    return http.delete(`v1/chat/${conversationId}/message/${messageId}`);
  }
}
