import Layout from '../../views/Layout.vue';


export default {
    path: '/store',
    name: 'store',
    component: Layout,
    redirect: {path: "/store"},
    children: [
      {
        path: "/store",
        name: "store",
        component: () => import('@/pages/store/Store.vue')
      },
      {
        path: "/new-product",
        name: "new-product",
        props: true,
        component: () => import('@/pages/store/NewProduct.vue')
      },
      {
        path: "/product",
        name: "product",
        props: true,
        component: () => import('@/pages/store/Product.vue')
      },
      {
        path: "/provider-product",
        name: "provider-product",
        props: true,
        component: () => import('@/pages/store/ProviderProduct.vue')
      },
      {
        path: "/new-provider-product",
        name: "new-provider-product",
        props: true,
        component: () => import('@/pages/store/NewProviderProduct.vue')
      },
      {
        path: "/warehouse",
        name: "warehouse",
        props: true,
        component: () => import('@/pages/store/Warehouse.vue')
      },
      {
        path: "/add-product-warehouse",
        name: "add-product-warehouse",
        props: true,
        component: () => import('@/pages/store/NewProductWarehouse.vue')
      },
      {
        path: "/product-details",
        name: "product-details",
        props: true,
        component: () => import('@/pages/store/ProductDetails.vue')
      },
      {
        path: "/order",
        name: "order",
        props: true,
        component: () => import('@/pages/store/Order.vue')
      },
      {
        path: "/order-details",
        name: "order-details",
        props: true,
        component: () => import('@/pages/store/OrderDetails.vue')
      },
      {
        path: "/orders-for-cancel",
        name: "orders-for-cancel",
        props: true,
        component: () => import('@/pages/store/CancelOrder.vue')
      },
      {
        path: "/order-cancel-details",
        name: "order-cancel-details",
        props: true,
        component: () => import('@/pages/store/CancelOrderDetails.vue')
      },
      {
        path: "/order-accept",
        name: "order-accept",
        props: true,
        component: () => import('@/pages/store/AcceptOrder.vue')
      },
      {
        path: "/order-accept-details",
        name: "order-accept-details",
        props: true,
        component: () => import('@/pages/store/AcceptOrderDetails.vue')
      },
    ]
};