import http from "../http-common";

export default class UserServices {
  async createNewUser(data) {
    let res = http.post("v1/user", data);
    return res;
  }

  async getAllUser() {
    let res = http.get("v1/user");
    return res;
  }

  async getAllLeaders() {
    let res = http.get("v1/user/get-leaders");
    return res;
  }

  async getPermissions(id) {
    let res = http.get(`v1/user/user-permissions?userId=${id}`);
    return res;
  }

  async tooglePermission(id, data) {
    let res = http.delete(
      `v1/user/enable-and-disabled-permission?userId=${id}`,
      { data: data }
    );
    return res;
  }

  async getAllUserInactive() {
    let res = http.get("v1/user/for-company-inactive");
    return res;
  }

  async getAllUserForCompany() {
    let res = http.get("v1/user/for-company");
    return res;
  }

  async getAllUserByGroup(data) {
    return http.put(`v1/user/for-group`, data);
  }

  async getUserIndicators() {
    return http.get(`v1/user/indicators`);
  }

  async getUserbyId(id) {
    let res = http.get("v1/user/" + id);
    return res;
  }

  async updateUserProfilebyId(id, data) {
    let res = http.put("v1/user/" + id, data);
    return res;
  }

  async updateUserbyId(id, data) {
    let res = http.put(`v1/user/${id}`, data);
    return res;
  }

  async disableUsers(data) {
    let res = http.put("v1/user/disabled", data);
    return res;
  }

  async importUser(data) {
    return http.post("v1/user/batch", data);
  }

  async userLevels() {
    return http.get("v1/user/user-level");
  }

  async historyUser(data) {
    return http.post(`v1/user/history-user`, data);
  }

  async logout() {
    return http.post(`v1/user/Logout`);
  }

  async getAllQuiz(tutorId) {
    return http.get(`v1/user/get-quiz/tutor/${tutorId}`);
  }

  async getQuizById(quizId, tutorId) {
    return http.get(`v1/user/quiz/${quizId}/tutor/${tutorId}`);
  }

  async getAvatars() {
    return http.get(`v1/user/ListImageProfile`);
  }

  async addAvatars(data) {
    return http.post(`v1/user/AddFileImage`, data);
  }

  async DeleteImageProfile(imageId, userId) {
    return http.delete(
      `v1/user/DeleteImageProfile?imageId=${imageId}&userId=${userId}`
    );
  }

  async removeAvatar(imageId, road) {
    return http.delete(`v1/user/DeleteImageBd?imageId=${imageId}&road=${road}`);
  }

  async addAvatar(imageId, userId) {
    return http.put(
      `v1/user/UpdateImageProfile?imageId=${imageId}&userId=${userId}`
    );
  }

  async getAllEmoji() {
    return http.get("v1/user/ListEmoji");
  }

  async addEmoji(title, data) {
    return http.post(`v1/user/AddEmojis?title=${title}`, data);
  }

  async deleteEmoji(data) {
    return http.delete(`v1/user/DeleteEmoji?emojiId=${data}`);
  }

  async updateEmojji(emojiId, title) {
    return http.put(`v1/user/update-emoji?emojiId=${emojiId}&title=${title}`);
  }

  async updateEmojiProfile(id) {
    return http.put(`v1/user/update-emoji-profile?emojiId=${id}`);
  }

  async getUserEmoji() {
    return http.get("v1/user/get-user-emoji");
  }

  async moodHistory(data) {
    return http.post("v1/user/Emoji-history", data);
  }

  async modifyOnlineStatus(status) {
    return http.put(`v1/user/Update-online?online=${status}`);
  }
}
