
<template>
<svg :width="width" :height="height" viewBox="0 0 16 16" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5 3H12V1.5C12 0.6875 11.3125 0 10.5 0H5.5C4.65625 0 4 0.6875 4 1.5V3H1.5C0.65625 3 0 3.6875 0 4.5V12.5C0 13.3438 0.65625 14 1.5 14H14.5C15.3125 14 16 13.3438 16 12.5V4.5C16 3.6875 15.3125 3 14.5 3ZM5.5 1.5H10.5V3H5.5V1.5ZM1.5 12.5V4.5H4V12.5H1.5ZM5.5 12.5V4.5H10.5V12.5H5.5ZM14.5 12.5H12V4.5H14.5V12.5Z" :fill="color"/>
</svg>


</template>

<script>

export default {
  name: "Company",
  props:{
    width:{
      type:Number,
      default:19
    },
    height:{
      type:Number,
      default:17
    },
    color:String
  },
  setup(){
  }
};
</script>

<style lang="scss" scoped></style>
