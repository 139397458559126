import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    userdata: Object,
    permissions: Array,
    menu: Array,
    breadcrumbs: Array,
    socket: Function,
    socketStreaming: Function,
    MessagesChat: [],
    blacklist: [],
    mood: Object,
    live: {
      name: null,
      id: null,
      token: null,
    },
    constraints: {
      camera: {
        deviceId: "default",
        resolution: {
          frameRate: 30,
          width: 1280,
          height: 720,
        },
      },
      microphone: {
        echoCancellation: true,
        noiseSuppression: true,
        sampleRate: 44100,
        surfaceSwitching: "include",
        selfBrowserSurface: "exclude",
        systemAudio: "exclude",
        deviceId: "default",
      },
      headphone: { deviceId: "default" },
      screenCapture: {
        video: { frameRate: { min: 24, ideal: 30, max: 60 } },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
          suppressLocalAudioPlayback: true,
        },
        surfaceSwitching: "include",
        selfBrowserSurface: "exclude",
        systemAudio: "exclude",
      },
    },
  },
  mutations: {
    setUserData(state, payload) {
      state.userdata = payload;
    },

    setLiveData(state, payload) {
      state.live.id = payload.id;
      state.live.token = payload.token;
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    },
    setMenu(state, payload) {
      state.menu = payload;
    },
    setBreadcrumbs(state, payload) {
      state.breadcrumbs = payload;
    },
    setSocket(state, payload) {
      state.socket = payload;
    },
    setBlacklist(state, payload) {
      state.blacklist = payload;
    },
    AddMessagesChat(state, payload) {
      if (
        !state.MessagesChat.some(
          (item) => item.conversationId === payload.conversationId
        )
      ) {
        state.MessagesChat.unshift(payload);
      }
    },
    RemoveMessagesChat(state, payload) {
      var index = state.MessagesChat.findIndex(
        (chat) => chat.conversationId == payload
      );
      if (index !== -1) {
        state.MessagesChat.splice(index, 1);
      }
    },
    setMood(state, payload) {
      state.mood = payload;
    },
    setOnlineStatus(state, payload) {
      state.userdata.online = payload;
    },

    setDeviceIdCamera(state, payload) {
      state.constraints.camera.deviceId = payload;
    },
    setDeviceIdMicrophone(state, payload) {
      state.constraints.microphone.deviceId = payload;
    },
    setDeviceIdHeadphone(state, payload) {
      if (payload != null) {
        state.constraints.headphone.deviceId = payload;
      }
    },
    setSocketStreaming(state, payload) {
      state.socketStreaming = payload;
    },
  },
  actions: {
    AddMessages({ commit }, payload) {
      commit("AddMessagesChat", payload);
    },
    RemoveMessages({ commit }, payload) {
      commit("RemoveMessagesChat", payload);
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
