<template>
<svg :width="width" :height="height" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 6C18.375 6 19.5 4.90625 19.5 3.5C19.5 2.125 18.375 1 17 1C15.5938 1 14.5 2.125 14.5 3.5C14.5 4.90625 15.5938 6 17 6ZM17 2.5C17.5312 2.5 18 2.96875 18 3.5C18 4.0625 17.5312 4.5 17 4.5C16.4375 4.5 16 4.0625 16 3.5C16 2.96875 16.4375 2.5 17 2.5ZM3 6C4.375 6 5.5 4.90625 5.5 3.5C5.5 2.125 4.375 1 3 1C1.59375 1 0.5 2.125 0.5 3.5C0.5 4.90625 1.59375 6 3 6ZM3 2.5C3.53125 2.5 4 2.96875 4 3.5C4 4.0625 3.53125 4.5 3 4.5C2.4375 4.5 2 4.0625 2 3.5C2 2.96875 2.4375 2.5 3 2.5ZM15.375 9.09375C14.5 7.84375 12.8438 7.34375 11.375 7.8125C10.9375 7.9375 10.4688 8 9.96875 8C9.5 8 9.03125 7.9375 8.59375 7.8125C7.125 7.34375 5.4375 7.8125 4.59375 9.09375C4.21875 9.6875 3.96875 10.375 3.96875 11.125V12.5C3.96875 13.3438 4.65625 14 5.46875 14H14.4688C15.3125 14 15.9688 13.3438 15.9688 12.5V11.125C16 10.375 15.75 9.6875 15.375 9.09375ZM14.5 12.5H5.5V11.125C5.5 9.96875 6.40625 9.03125 7.53125 9C8.3125 9.34375 9.15625 9.5 10 9.5C10.8125 9.5 11.6562 9.34375 12.4375 9C13.5625 9.03125 14.5 9.96875 14.5 11.125V12.5ZM17.375 7H16.625C16.0625 7 15.5625 7.1875 15.1562 7.46875C15.5625 7.78125 15.9375 8.15625 16.2188 8.59375C16.3438 8.5625 16.4688 8.5 16.5938 8.5H17.3438C17.9688 8.5 18.4688 9.03125 18.4688 9.625C18.4688 10.0625 18.8125 10.375 19.2188 10.375C19.6562 10.375 19.9688 10.0625 19.9688 9.625C20 8.1875 18.8125 7 17.375 7ZM10 7C11.9062 7 13.5 5.4375 13.5 3.5C13.5 1.59375 11.9062 0 10 0C8.0625 0 6.5 1.59375 6.5 3.5C6.5 5.4375 8.0625 7 10 7ZM10 1.5C11.0938 1.5 12 2.40625 12 3.5C12 4.625 11.0938 5.5 10 5.5C8.875 5.5 8 4.625 8 3.5C8 2.40625 8.875 1.5 10 1.5ZM4.8125 7.46875C4.40625 7.1875 3.90625 7 3.375 7H2.625C1.15625 7 0 8.1875 0 9.625C0 10.0625 0.3125 10.375 0.75 10.375C1.15625 10.375 1.5 10.0625 1.5 9.625C1.5 9.03125 2 8.5 2.625 8.5H3.375C3.5 8.5 3.625 8.5625 3.75 8.59375C4.03125 8.15625 4.40625 7.78125 4.8125 7.46875Z" :fill="color"/>
</svg>

</template>

<script>
export default {
  name: "Groups",
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 14,
    },
    color: String,
  },
  setup() {},
};
</script>

<style lang="scss" scoped></style>
