<template>
<svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.5 7.5V13C2.5 13.2812 2.71875 13.5 3 13.5H9C9.25 13.5 9.5 13.2812 9.5 13V7.5C9.5 7.25 9.25 7 9 7H3C2.71875 7 2.5 7.25 2.5 7.5ZM6.5 8.5H8.5V10H6.5V8.5ZM6.5 11H8.5V12.5H6.5V11ZM3.5 8.5H5.5V10H3.5V8.5ZM3.5 11H5.5V12.5H3.5V11ZM11.5312 3.0625L8.90625 0.46875C8.625 0.1875 8.25 0 7.875 0H1.46875C0.65625 0.03125 0 0.6875 0 1.53125V14.5312C0 15.3438 0.65625 16 1.46875 16H10.4688C11.3125 16 12 15.3438 12 14.5312V4.125C12 3.75 11.8125 3.34375 11.5312 3.0625ZM7.96875 1.625L10.375 4.03125H7.96875V1.625ZM10.5 14.5312H1.46875V1.53125H6.46875V4.78125C6.46875 5.1875 6.8125 5.53125 7.21875 5.53125H10.5V14.5312Z" :fill="color"/>
</svg>
</template>

<script>
export default {
name: "Data",
props: {
  width: {
    type: Number,
    default: 20,
  },
  height: {
    type: Number,
    default: 18,
  },
  color: String,
},
setup() {},
};
</script>

<style lang="scss" scoped></style>
