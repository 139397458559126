<template>
 <div class="store-btn q-my-lg">
      <q-img
        width="25px"
        src="@/assets/icons/store.svg"
        spinner-color="white"
      />
      <p>Loja</p>
    </div>
</template>


<script>
export default {
  name: "StoreBtn",
};
</script>


<style lang="scss" scoped>


.store-btn {
  border-radius: 40px;
  background: linear-gradient(to right, #fc9c49, #ff0488);

  display: flex;
  align-items: flex-end;
  justify-content: center;

  padding: 5%;
  cursor: pointer;

  p {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 1rem;
    margin: 0 0.5rem;
    color: #ffffff;
    text-decoration: none;
  }
}
</style>