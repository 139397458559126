<template>
  <div class="layout">
    <div class="menu">
      <SideBar />
    </div>
    <div class="main">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/sidebar/SideBar.vue";
import { ref } from "vue";

export default {
  name: "Home",
  components: {
    SideBar,
  },

  setup() {
    return {
      leftDrawerOpen: ref(true),
    };
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: var(--q-primary);
  overflow: auto;

  .menu .main {
    width: 100vw;
  }

  @media (min-width: 1040px) {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .menu {
      width: 20vw;
    }

    .main {
      width: 80vw;
    }
  }
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;

  .content {
    padding: 5% 0;
    background: #fdfdfd;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    overflow-y: auto;

    @media (min-width: 800px) {
      padding: 0.5% 0;
    }
  }
}
</style>
