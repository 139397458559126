<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5312 0C17.1562 0 12.6875 0.21875 10 1.78125C7.28125 0.21875 2.8125 0 2.4375 0C1.09375 0 0 1.09375 0 2.40625V12.2188C0 13.4688 1 14.5312 2.28125 14.5938C3.4375 14.6562 6.3125 14.9062 8.15625 15.8125C8.40625 15.9375 8.6875 16 8.96875 16H11C11.2812 16 11.5625 15.9375 11.8125 15.8125C13.6562 14.9062 16.5312 14.6562 17.6875 14.5938C18.9688 14.5312 20 13.4688 20 12.2188V2.40625C20 1.09375 18.875 0 17.5312 0ZM9.25 13.7188C9.25 14.0625 8.90625 14.3125 8.5625 14.1875C6.5625 13.4062 3.75 13.1875 2.375 13.0938C1.875 13.0625 1.5 12.6875 1.5 12.2188V2.40625C1.5 1.90625 1.90625 1.5 2.4375 1.5C3.03125 1.53125 6.375 1.75 8.625 2.78125C9 2.9375 9.21875 3.3125 9.21875 3.6875L9.25 7V13.7188ZM18.5 12.2188C18.5 12.6875 18.0938 13.0625 17.5938 13.0938C16.2188 13.1875 13.4062 13.4062 11.4062 14.1875C11.0625 14.3125 10.75 14.0625 10.75 13.7188V7V3.6875C10.75 3.3125 10.9688 2.9375 11.3438 2.78125C13.5938 1.75 16.9375 1.53125 17.5312 1.5C18.0625 1.5 18.5 1.90625 18.5 2.40625V12.2188Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Knowledge",
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 16,
    },
    color: String,
  },
  setup() {},
};
</script>

<style lang="scss" scoped></style>
