import http from "../http-common";

export default class CompanyServices {
  async add(data) {
    return http.post("v1/company", data);
  }

  async addBadlanguage(data) {
    return http.post("v1/company/add-badlanguage", data);
  }

  async getAllbadlanguages(companyId) {
    return http.get(`v1/company/badlanguages?companyId=${companyId}`);
  }

  async updateBadlanguage(badlanguageId, data) {
    return http.put(
      `v1/company/update-badlanguage?badlanguageId=${badlanguageId}`,
      data
    );
  }

  async disabledBadlanguage(badlanguageId) {
    return http.delete(
      `v1/company/disabled-badlanguage?badlanguageId=${badlanguageId}`
    );
  }

  async getAll() {
    return http.get("v1/company");
  }

  async get(companyId) {
    return http.get(`v1/company/${companyId}`);
  }

  async update(companyId, data) {
    return http.put(`v1/company/${companyId}`, data);
  }

  async disabled(companyId) {
    return http.delete(`v1/company/${companyId}`);
  }
}
