import Layout from "../../views/Layout.vue";

//-----UTILS
import CanUserAccessClass from "../../utils/CanUserAccessClass";

let canUserAccessClass = new CanUserAccessClass();

export default {
  path: "/courses",
  name: "courses",
  component: Layout,
  redirect: { path: "/home" },
  children: [
    {
      path: "/knowledge",
      name: "knowledge",
      component: () => import("@/pages/courses/knowledge/Knowledge.vue"),
    },
    {
      path: "/training",
      name: "training",
      component: () => import("@/pages/courses/trail/Training.vue"),
    },
    {
      path: "/new-training",
      name: "new-training",
      component: () => import("@/pages/courses/trail/NewTraining.vue"),
      beforeEnter: (to, from, next) => {
        if (canUserAccessClass.canUserAccess(to.name)) {
          next();
        } else {
          next({ name: "training", query: from.query });
        }
      },
    },
    {
      path: "/new-trail",
      name: "new-trail",
      props: true,
      component: () => import("@/pages/courses/knowledge/NewTrail.vue"),
      beforeEnter: (to, from, next) => {
        if (canUserAccessClass.canUserAccess(to.name)) {
          next();
        } else {
          next({ name: "knowledge" });
        }
      },
    },
    {
      path: "/finish-trail",
      name: "finish-trail",
      props: true,
      component: () => import("@/pages/courses/knowledge/FinishTrail.vue"),
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: "/new-class",
      name: "new-class",
      props: true,
      component: () => import("@/pages/courses/knowledge/NewClass.vue"),
      beforeEnter: (to, from, next) => {
        if (canUserAccessClass.canUserAccess(to.name)) {
          next();
        } else {
          next({ name: "knowledge" });
        }
      },
    },
    {
      path: "/group-trail",
      name: "group-trail",
      props: true,
      component: () => import("@/pages/courses/knowledge/GroupsTrail.vue"),
    },
    {
      path: "/add-groups",
      name: "add-groups",
      props: true,
      component: () => import("@/pages/courses/knowledge/AddGroups.vue"),
    },
    {
      path: "/add-users-in-class",
      name: "add-users-in-class",
      props: true,
      component: () => import("@/pages/courses/knowledge/AddUsersInClass.vue"),
    },
    {
      path: "/database-questions",
      name: "database-question",
      props: true,
      component: () => import("@/pages/courses/Training/DatabaseQuestions.vue"),
    },
    {
      path: "/new-content",
      name: "new-content",
      component: () => import("@/pages/courses/Training/NewContent.vue"),
      beforeEnter: (to, from, next) => {
        if (canUserAccessClass.canUserAccess(to.name)) {
          next();
        } else {
          next({ name: "training-page", query: from.query });
        }
      },
    },
    {
      path: "/generate-evaluation",
      name: "generate-evaluation",
      component: () =>
        import("@/pages/courses/Training/GenerateEvaluation.vue"),
    },
    {
      path: "/training-page",
      name: "training-page",
      component: () => import("@/pages/courses/Training/TrainingPage.vue"),
    },
    {
      path: "/open-question-correction",
      name: "open-question-correction",
      component: () =>
        import("@/pages/courses/Training/OpenQuestionCorrection.vue"),
    },
    {
      path: "/correction",
      name: "correction",
      component: () => import("@/pages/courses/Training/Correction.vue"),
    },
    {
      path: "/view-open-questions",
      name: "view-open-questions",
      component: () => import("@/pages/student/exams/ViewFixedQuestions.vue"),
    },
    {
      path: "/preview-live",
      name: "preview-live",
      component: () => import("@/pages/LiveClass/previewLive/PreviewLive.vue"),
    },
    {
      path: "/live",
      name: "live",
      component: () => import("@/pages/LiveClass/Live/Live.vue"),
    },
  ],
};
