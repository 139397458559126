import './styles/quasar.sass'
import lang from 'quasar/lang/pt-BR.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      primary: '#ff0000',
    }
  },
  plugins: [Dialog, Notify, AppFullscreen],
  lang: lang
}