import Layout from "../../views/Layout.vue";

export default {
  path: "/admin",
  name: "admin",
  component: Layout,
  redirect: { path: "/home" },
  children: [
    {
      path: "/new-user",
      name: "new-user",
      component: () => import("@/pages/admin/users/Newuser.vue"),
    },
    {
      path: "/tutor-page",
      name: "tutor-page",
      component: () => import("@/pages/admin/Tutors.vue"),
    },
    {
      path: "/student-page",
      name: "student-page",
      component: () => import("@/pages/admin/Students.vue"),
    },
    {
      path: "/groups",
      name: "groups",
      component: () => import("@/pages/admin/groups/Groups.vue"),
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/pages/admin/users/Users.vue"),
    },
    {
      path: "/user-batch",
      name: "user-batch",
      component: () => import("@/pages/admin/users/BatchRegistrationUsers.vue"),
    },
    {
      path: "/new-group",
      name: "new-group",
      component: () => import("@/pages/admin/groups/NewGroup.vue"),
    },
    {
      path: "/newgroupstudent-page",
      name: "newgroupstudent-page",
      component: () => import("@/pages/admin/groups/NewGroupStudent.vue"),
    },
    {
      path: "/newgrouptutors-page",
      name: "newgrouptutors-page",
      component: () => import("@/pages/admin/groups/NewGroupTutors.vue"),
    },
    {
      path: "/editgroup-page",
      name: "editgroup-page",
      component: () => import("@/pages/admin/groups/EditGroup.vue"),
    },
    {
      path: "/company",
      name: "company",
      component: () => import("@/pages/admin/company/Company.vue"),
    },
    {
      path: "/company-configuration",
      name: "company-configuration",
      component: () => import("@/pages/admin/company/CompanyConfiguration.vue"),
    },
    {
      path: "/new-company",
      name: "new-company",
      component: () => import("@/pages/admin/company/NewCompany.vue"),
    },
    {
      path: "/reports",
      name: "reports",
      component: () => import("@/pages/admin/reports/Reports.vue"),
    },
    {
      path: "/history",
      name: "history",
      component: () => import("@/pages/admin/history/Reports.vue"),
    },
    {
      path: "/avatars-gallery",
      name: "avatars-gallery",
      component: () => import("@/pages/admin/company/AvatarsGallery.vue"),
    },
  ],
};
